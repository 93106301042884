import PropTypes from 'prop-types';
import {Link as RouterLink} from 'react-router-dom';
// @mui
// import { useTheme } from '@mui/material/styles';
import {Box} from '@mui/material';
import {ReactComponent as ILogo} from 'src/assets/inverted.svg';
import useResponsive from 'src/hooks/useResponsive';
// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({disabledLink = false, sx}) {
	const isDesktop = useResponsive('up', 'md');

	// const PRIMARY_LIGHT = theme.palette.primary.light;

	// const PRIMARY_MAIN = theme.palette.primary.main;

	// const PRIMARY_DARK = theme.palette.primary.dark;

	// OR
	// const logo = <Box component="img" src="/static/logo.svg" sx={{ width: 40, height: 40, ...sx }} />

	const logo = (
		<Box sx={{...sx, marginLeft: isDesktop ? '' : -3}}>
			<ILogo height={isDesktop ? undefined : 35} />
		</Box>
	);

	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="/">{logo}</RouterLink>;
}

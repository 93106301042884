import {Suspense, lazy} from 'react';
import {Navigate, useLocation, useRoutes} from 'react-router-dom';

import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import AuthGuard from './Guards/AuthGuard';
import Loader from './components/LoadingScreen';
import GuestGuard from './Guards/GuestGuard';
import {PATH_AFTER_LOGIN} from './config';
import TwoFactorGuard from './Guards/TwoFactorGuard';

// ----------------------------------------------------------------------
const Loadable = (Component) => (props) => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const {pathname} = useLocation();

	return (
		<Suspense fallback={<Loader isDashboard={pathname.includes('/dashboard')} />}>
			<Component {...props} />
		</Suspense>
	);
};
export default function Router() {
	return useRoutes([
		{
			path: '/auth',
			// element: <LogoOnlyLayout />,
			children: [
				{
					path: 'login',
					element: (
						<GuestGuard>
							<Login />
						</GuestGuard>
					),
				},
				{
					path: 'register',
					element: (
						<GuestGuard>
							<Register />
						</GuestGuard>
					),
				},
				{
					path: 'two-factor',
					element: (
						<TwoFactorGuard>
							<TwoFactor />
						</TwoFactorGuard>
					),
				},
				{
					path: 'preregister',
					element: (
						<GuestGuard>
							<PreRegister />
						</GuestGuard>
					),
				},
				{
					path: 'forgot-password',
					element: (
						<GuestGuard>
							<ForgotPassword />
						</GuestGuard>
					),
				},
				{
					path: 'reset-password',
					element: (
						<GuestGuard>
							<ResetPassword />
						</GuestGuard>
					),
				},
				{
					path: 'create-password',
					element: (
						<GuestGuard>
							<CreatePassword />
						</GuestGuard>
					),
				},
				{
					path: 'verify-email',
					element: <VerifyEmail />,
				},
			],
		},
		{
			path: '/dashboard',
			element: (
				<AuthGuard>
					<DashboardLayout />
				</AuthGuard>
			),
			children: [
				{
					element: <Navigate to={PATH_AFTER_LOGIN} replace />,
					index: true,
				},
				{path: 'app', element: <DashboardApp />},
				{path: 'user', element: <User />},
				// { path: 'products', element: <Products /> },
				// { path: 'blog', element: <Blog /> },
				{path: 'transactions', element: <Transactions />},
				{path: 'cars', element: <Garages />},
				{path: 'link_wallet', element: <LinkWallet />},
				{
					path: 'beneficiary',
					element: <ListBeneficiary />,
				},
				{path: 'tokens', element: <UserTokens />},
				{path: 'settings', element: <Settings />},
				{
					path: 'fleet-management',
					children: [
						{path: 'drivers', element: <ListDrivers />},
						{path: 'drivers/:id', element: <Drivers />},
						{path: 'staff', element: <ListStaffs />},
						{path: 'staff/:id', element: <Staffs />},
						{path: 'sub-fleets', element: <ListSubFleet />},
						{path: 'subfleet/:id', element: <SubFleet />},

						{path: 'expired-tokens', element: <ExpiredTokens />},
						{path: 'drivers/add', element: <></>},
					],
				},
				{
					path: 'fleet-managers',
					element: <BusinessFLeets />,
				},
				{
					path: 'fleet-managers/:id',
					element: <FLeet />,
				},
				{
					path: 'user-guide',
					element: <UserGuide />,
				},
				{
					path: 'booking',
					element: <Booking />,
					// children: [
					// 	{ path: "buy-fuel", element: <BuyFuel /> },
					// ]
				},
				{
					path: 'instant-quote',
					element: <InstantQuotes />,
					// children: [
					// 	{ path: "buy-fuel", element: <BuyFuel /> },
					// ]
				},
				{path: 'booking/buy-fuel', element: <BuyFuel />},
				{path: 'booking/buy-oil', element: <BuyOil />},
				{path: 'booking/car-scan', element: <CarScan />},
				{path: 'booking/car-wash', element: <CarWash />},
				{
					path: 'booking/home-service',
					element: <HomeService />,
				},
				{
					path: 'booking/oil-change',
					element: <OilChange />,
				},
				{
					path: 'booking/paint-care',
					element: <PaintCar />,
				},
				{path: 'booking/repairs', element: <Repairs />},
				{path: 'booking/roadside', element: <Roadside />},
				{
					path: 'booking/wheels-booking',
					element: <WheelsBooking />,
				},
				{
					path: 'instant-quote/spare-parts',
					element: <SpareParts />,
				},
				{
					path: 'instant-quote/batteries',
					element: <Batteries />,
				},
				{
					path: 'instant-quote/lubricants',
					element: <Lubricants />,
				},
				{
					path: 'instant-quote/tyres',
					element: <Tyres />,
				},
			],
		},

		{path: '*', element: <Navigate to="/404" replace />},
		{
			path: '/',
			// element: <MainLayout />,
			children: [{element: <Home />, index: true}],
		},
		{
			path: '*',
			element: <LogoOnlyLayout />,
			children: [
				{
					path: '*',
					element: <Navigate to="/404" replace />,
				},
				{path: '404', element: <NotFound />},
			],
		},
	]);
}

const Home = Loadable(lazy(() => import('./pages/Home')));
const User = Loadable(lazy(() => import('./pages/User')));
const Login = Loadable(lazy(() => import('./pages/Login')));
const TwoFactor = Loadable(lazy(() => import('./pages/TwoFactorAuth')));
const NotFound = Loadable(lazy(() => import('./pages/Page404')));
const Register = Loadable(lazy(() => import('./pages/Register')));
const CreatePassword = Loadable(lazy(() => import('./pages/CreatePassword')));
const VerifyEmail = Loadable(lazy(() => import('./pages/VerifyEmail')));
// const Products = Loadable(lazy(() => import('./pages/Products')));
const DashboardApp = Loadable(lazy(() => import('./pages/DashboardApp')));
const Transactions = Loadable(lazy(() => import('./pages/Transaction')));
const PreRegister = Loadable(lazy(() => import('./pages//PreRegister')));
const ListDrivers = Loadable(lazy(() => import('./pages/fleet/ListDrivers')));
const Drivers = Loadable(lazy(() => import('./pages/fleet/Driver')));
const UserTokens = Loadable(lazy(() => import('./pages/FuelTokens')));
const Settings = Loadable(lazy(() => import('./pages/UserAccount')));
const ListBeneficiary = Loadable(lazy(() => import('./pages/Beneficiary')));
const Garages = Loadable(lazy(() => import('./pages/Garages')));
const ForgotPassword = Loadable(lazy(() => import('./pages/ForgotPassword')));
const ResetPassword = Loadable(lazy(() => import('./pages/ResetPassword')));
const Staffs = Loadable(lazy(() => import('./pages/fleet/Staff')));
const SubFleet = Loadable(lazy(() => import('./pages/fleet/SubFLeeet')));
const ListStaffs = Loadable(lazy(() => import('./pages/fleet/ListStaff')));
const ListSubFleet = Loadable(lazy(() => import('./pages/fleet/ListSubFleet')));
const InstantQuotes = Loadable(lazy(() => import('./pages/InstantQuotes')));
const LinkWallet = Loadable(lazy(() => import('./pages/LinkWallet')));
const UserGuide = Loadable(lazy(() => import('./pages/UserGuide')));
const ExpiredTokens = Loadable(lazy(() => import('./pages/fleet/ExpiredTokens')));
const BusinessFLeets = Loadable(lazy(() => import('./pages/business-fleet/ListFleetManagers')));
const FLeet = Loadable(lazy(() => import('./pages/business-fleet/Fleet')));

//Booking
const Booking = Loadable(lazy(() => import('./pages/booking')));
const BuyFuel = Loadable(lazy(() => import('./pages/booking/BuyFuel')));
const BuyOil = Loadable(lazy(() => import('./pages/booking/BuyOil')));
const CarScan = Loadable(lazy(() => import('./pages/booking/CarScan')));
const CarWash = Loadable(lazy(() => import('./pages/booking/CarWash')));
const HomeService = Loadable(lazy(() => import('./pages/booking/HomeService')));
const OilChange = Loadable(lazy(() => import('./pages/booking/OilChange')));
const PaintCar = Loadable(lazy(() => import('./pages/booking/PaintCar')));
const Repairs = Loadable(lazy(() => import('./pages/booking/Repairs')));
const Roadside = Loadable(lazy(() => import('./pages/booking/Roadside')));
const WheelsBooking = Loadable(lazy(() => import('./pages/booking/WheelsBooking')));

// Instant Quote
const SpareParts = Loadable(lazy(() => import('./pages/quotes/SpareParts')));
const Batteries = Loadable(lazy(() => import('./pages/quotes/Battery')));
const Lubricants = Loadable(lazy(() => import('./pages/quotes/Lubricants')));
const Tyres = Loadable(lazy(() => import('./pages/quotes/Tyres')));
